<template>
  <b-modal id="action-Plan" @hidden="$emit('resetModal')" :title="selectedRegion ? 'Update Region' : 'Add Region'"
    hide-footer>
    <div>
      <mapPathView v-on:onRemove="removeItem" :showPolyLine="true" :polylinePaths="selectedRegion ? [polylineData] : [polylineData]" :customHeight="500"
        v-on:locationChange="locationChange" :zoom="5" :center="center" :markerLocations="selectedRegion ? values.boundary_coordinates : values.boundary_coordinates" />
    </div>

    <div class="map-container">

    </div>
    <div>
      <!-- <mapPathView v-if="selectedRegion" :showPolyLine="true" :polylinePaths="polylineData" :customHeight="500"
        v-on:locationChange="locationChange" :zoom="5" :center="center"   /> -->

    </div>



    <!-- <mapPathView v-if="this.showPolyLines" :showPolyLine="true" :polylinePaths="polylineData || []" :customHeight="500"
  v-on:locationChange="locationChange" :zoom="5" :center="center"  />
</div> -->

    <br>
    <FormulateForm name="PlanAction" v-model="values" :schema="schema" @submit="submitHandler">

      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedRegion"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedRegion"> Update </span>
          <span v-else> Add </span>
          Region</span>
      </FormulateInput>


    </FormulateForm>

    <!-- <div>
      <gmap-map :center="initialPosition" :zoom="9" @click="onMapClick" ref="map" style="width: 100%; height: 400px">
        <gmap-polyline v-if="drawing" :path="polyline" :options="polylineOptions"></gmap-polyline>
      </gmap-map> -->
    <!-- <button @click="toggleDrawingMode" class="drawing-button">
        {{ buttonText }}
      </button>

  <button @click="toggleDrawing">{{ drawing ? 'Stop Drawing' : 'Start Drawing' }}</button> 
    </div>  -->

    <!-- <div>
    <gmap-map
      :center="center"
      :zoom="zoom"
      @click="onMapClick"
    >
      <gmap-polyline v-if="drawing" :path="polyline" :options="polylineOptions"></gmap-polyline>
    </gmap-map>
  </div> -->
  </b-modal>
</template>

<script>
// let drawingManager;

import { regions } from "@/config/api/region";
import mapPathView from "@/components/maps/mapPathView";
// import { LMap, LTileLayer, LPolyline, LTooltip, LMarker } from 'vue2-leaflet';
// import Vue2Leaflet from '@vue2-leaflet';

export default {
  components: {
    mapPathView,
    // LMap,
    // LTileLayer,
    // LPolyline,
    // LTooltip,
    // LMarker
  },
  props: ["selectedRegion", "drawing"],
  data() {
    return {
      markers: [],
      showPolyLines: false,
      marker: { position: { lat: 24.7136, lng: 46.6753 } },
      coordinate: {},
      zoom: 12,
      // center: [37.7749, -122.4194], // Initial map center
      tileLayerUrl: 'https://api.mapbox.com/styles/v1/testerastester/clora43bn00ow01qmcsld9iob/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGVzdGVyYXN0ZXN0ZXIiLCJhIjoiY2xvNWN2YXd2MGZsNDJtbnpsdDJkMXF3aSJ9.ffhOVbYZY5AQv_4g3wV3Kg',
      // https://api.mapbox.com/styles/v1/testerastester/clora43bn00ow01qmcsld9iob/tiles/256/[…]oiY2xvNWN2YXd2MGZsNDJtbnpsdDJkMXF3aSJ9.ffhOVbYZY5AQv_4g3wV3Kg
      //'https://api.mapbox.com/styles/v1/testerastester/clor8qjb700oq01prf3jlgbal/tiles/256/[…]oiY2xvNWN2YXd2MGZsNDJtbnpsdDJkMXF3aSJ9.ffhOVbYZY5AQv_4g3wV3Kg',

      tileLayerOptions: {
        // id: 'mapbox.mapbox-satellite-v4',
        interactive: true,
        dragging: true,
        accessToken: 'pk.eyJ1IjoidGVzdGVyYXN0ZXN0ZXIiLCJhIjoiY2xvNWN2YXd2MGZsNDJtbnpsdDJkMXF3aSJ9.ffhOVbYZY5AQv_4g3wV3Kg',
      },

      //drawing: true,



      //'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //'https://{s}.maps.stamen.com/{z}/{x}/{y}.png',
      //'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // drawing: true,
      currentLine: [],
      polylines: [],
      // center: [25.14707733795921, 47.24683801803494],
      center1: [47.24683801803494, 25.14707733795921],
      buttonText: 'Start Drawing',
      initialPosition: { lat: 24.76267610396201, lng: 46.809444829558366 },
      showPolyLine: true,
      c: [],
      polylineData: [],
      drawnLine: [],
      mapOptions: {
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              { visibility: 'on' },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [
              { visibility: 'on' },
            ],
          },
        ],
        // Disable map interactivity when drawing is active
        interactive: true,
        dragging: true,
      },

      //  polylineData: [
      //   [
      //   {lat: 25.14707733795921,lng: 47.24683801803494},
      //   {lat: 24.93680785517937,lng: 47.34296839023918},
      //     // { lat: 24.7136, lng: 46.6753 },
      //     // { lat: 24.7236, lng: 46.6853 },
      //   //     {lat: 25.1470,lng: 47.2468 },
      //   // {lat: 24.9368,lng: 47.3429},
      //   ],
      //   // [
      //   //   { lat: 24.7236, lng: 46.6853 },
      //   //   { lat: 24.7336, lng: 46.6953 },
      //   // ],
      // ],
      heatLocations: [
        [
          { lat: 1.33, lng: 103.75 },
          { lat: 1.43, lng: 103.85 },
          { lat: 1.53, lng: 103.70 },
        ],
      ],

      markerLatLng: [47.24683801803494, 25.14707733795921],
      center: { lat: 25.190074301612864, lng: 47.1520809379568 },
      firstcoordinate: {},
      // { lat: 47.1520809379568, lng: 25.190074301612864 },
      //{ lat: 47.1520809379568, lng: 25.190074301612864  },
      // center: {lat: 25.14707733795921, lng: 47.24683801803494},


      // center: { lat: 25.14707733795921, lng: 47.24683801803494 },
      defaultLocation: {
        lat: 1.43,
        lng: 103.85,
        zoom: 10,
      },

      boundary_coordinates: [],
      values: {
        boundaryCoordinates: [],
        coordinates: {},
        coord: [],
      },
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 7,
          type: "textarea",
          name: 'boundaryCoordinates',
          label: "Boundary Coordinates",
          validation: "required",
          placeholder: "Enter coordinates",
          validationMessages: {
            required: "Boundary Coordinates is required",
          },
          help: "Enter a pair of coordinates ex: [[47.24683801803494,25.14707733795921],[47.34296839023918,24.93680785517937],[47.10538904179157,24.696238150015468],[47.213879032993646,24.450202586346926]]"
        },

      ],
    };
  },

  computed() {
    if (this.selectedRegion) {
      this.drawing = false;
      console.log("draewing", this.drawing);
    }

  },
  mounted() {
    console.log("center", this.center);
    console.log("region", this.selectedRegion);
    if (this.selectedRegion) {
      console.log("drawing", this.drawing);
      this.drawing = false;
    }


    this.values.boundaryCoordinates = JSON.stringify(this.boundary_coordinates);
    console.log("boundary", this.values.boundaryCoordinates);
    this.values.coordinates = this.convertCoordinates(JSON.parse(this.values.boundaryCoordinates));
    console.log("coord", this.values.coordinates);

  },

  created() {

    if (this.selectedRegion) {
      this.drawing = false;

      console.log("drawingdsfs", this.drawing);
    }
    this.$nextTick(() => {
      this.polyline = [
        [
          { lat: 25.14707733795921, lng: 47.24683801803494 },
          { lat: 24.93680785517937, lng: 47.34296839023918 },
        ]
      ];
    });
  },

  methods: {
    removeItem(ele) {
      const lat = ele.lat;
      const lng = ele.lng;
      this.polylineData=this.polylineData.filter((element)=>!(element.lat==lat && element.lng==lng))
     
      this.markers=this.markers.filter((element)=>!(element.position.lat==lat && element.position.lng==lng))
      this.values.boundary_coordinates = this.markers.map(item => ({
        lat: item.position.lat,
        lng: item.position.lng
      }));
      console.log(ele, "elel")
    },
    getPolyline() {
      this.showPolyLines = true;
    },

    locationChange(val) {
      console.log("locationChange", val);
      const lat = val.lat;
      const lng = val.lng;

      this.markers.push({ position: { lat, lng } });

      this.polylineData.push({ lat, lng });
      this.values.boundary_coordinates = this.markers.map(item => ({
        lat: item.position.lat,
        lng: item.position.lng
      }));

      this.values.boundaryCoordinates = JSON.stringify(this.values.boundary_coordinates.map(item => [item.lng, item.lat]));
      console.log("bccccc", this.values.boundary_coordinates.map(item => [item.lat, item.lng]))
      console.log("bcc", this.values.boundaryCoordinates);

      // You can also pan the map to the new marker if needed
      //this.panToMarker({ lat, lng });
    },

    startDrawing() {
      console.log("draw", this.drawing);
      this.drawing = true;
      this.currentLine = [];
      this.polylines = [];
      // Disable map interactivity when drawing starts
      this.mapOptions.interactive = false;
      this.tileLayerOptions.interactive = false;
    },

    start() {
      this.selectedRegion = !this.selectedRegion;
    },
    endDrawing() {
      console.log("draw end", this.drawing);
      this.drawing = false;
      if (this.currentLine.length > 1) {
        this.drawnLine = [...this.drawnLine, ...this.currentLine];
        console.log("draw", this.drawnLine);
        this.polylines.push(this.currentLine);
      }

      // Enable map interactivity when drawing ends
      this.mapOptions.interactive = true;
      this.tileLayerOptions.interactive = true;
    },
    // startDrawing() {
    //   console.log("this.drawing", this.drawing);
    //   this.drawing = true;
    //   this.currentLine = [];
    // },
    // endDrawing() {
    //   this.drawing = false;
    //   if (this.currentLine.length > 1) {
    //     this.polylines.push(this.currentLine);
    //   }
    //   this.currentLine = [];
    // },

    // onMapClick(e) {
    //   if (this.drawing) {
    //     this.currentLine.push([e.latlng.lat, e.latlng.lng]);
    //   }
    // },

    getCoordinates() {
      // Output the coordinates of the drawn line to the console
      console.log("sdfsd", this.drawnLine);
      this.values.boundary_coordinates = this.drawnLine;
      // console.log("BC", JSON.stringify(this.values.boundary_coordinates));
      this.values.boundaryCoordinates = JSON.stringify(this.values.boundary_coordinates);
      // this.values.coordinates = this.convertCoordinates(JSON.parse(this.values.boundaryCoordinates));
    },

    async submitHandler(data) {
      if (this.selectedRegion) {
        this.updateRegion(data, this.selectedRegion._id);
      } else {
        this.addRegion(data);

      }
    },

    toggleDrawing() {
      //this.drawing = true;
      this.values.boundaryCoordinates = [];
      this.values.boundary_coordinates = [];
      
      this.polylineData = [];
    },

    convertCoordinates(data) {
      if (!Array.isArray(data)) {
        console.error("Data is not an array.");
        return [];
      }
      console.log("dataconvert", data);
      return data.map(coordinates => ({
        lat: coordinates[1],
        lng: coordinates[0],
      }));
    },


    addRegion(data) {
      console.log("data", data);
      console.log("data", data.boundary_coordinates);
      this.performAction = true;
      const api = regions.create;
      data.boundary_coordinates = JSON.parse(data.boundaryCoordinates);
      console.log("draw", data.boundary_coordinates);
      console.log(api.data);
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("PlanAction");
          this.$bvToast.toast("Region added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Region is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },

    updateRegion(data, id) {
      this.polylineData = [];
      console.log("draw", this.drawing);
      data.boundary_coordinates = JSON.parse(data.boundaryCoordinates);
      this.performAction = true;
      const api = regions.update;
      api.data = data;
      console.log("region new", api.data);

      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log("region new", res.data);

          this.$bvToast.toast("Region updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Region is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedRegion(val) {
      if (val) {

        this.values = { ...val };

        if (val.boundary_coordinates) {
          this.values.boundaryCoordinates = JSON.stringify(val.boundary_coordinates);
          console.log("boun", this.values.boundaryCoordinates)
          this.values.coordinates = this.convertCoordinates(JSON.parse(this.values.boundaryCoordinates));
          this.values.coord = this.values.coordinates.map(item => ({
            lat: item.lat,
            lng: item.lng
          }));

          this.polylineData = [this.values.coord];
          this.firstcoordinate = this.polylineData[0][0];

          this.center = this.firstcoordinate ? this.firstcoordinate : { lat: 25.190074301612864, lng: 47.1520809379568 },
            console.log("center", this.center);
          console.log("asdf", this.values.boundaryCoordinates);
        //  this.polylineData = this.polylineData;
        } else {
          this.values.boundaryCoordinates = ''; // Handle the case when boundary_coordinates is not available
       
        }
        console.log("length", val.length);
        this.drawing = val.length === 0;
        console.log("sdfadsfasdfadsf", this.drawing);
        this.polylineData = [];

      }

    },

  },
};
</script>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}

.payment-duration .formulate-input-label {
  margin-top: -6px;
}

.drawing-button {
  cursor: pointer;
}

.map-cursor-crosshair {
  cursor: crosshair;

}

.crosshair-cursor {
  cursor: crosshair !important;
}

.map-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
</style>
